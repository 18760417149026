* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  /* font-size: 10px; */
  /* 10px / 16px = 0.625 = 62.5% */
  /* Percentage of user's browser font-size setting */
  font-size: 62.5%;
  height: 100%;
  width: 100%;
  scroll-behavior: smooth;
  /* overflow: hidden; */
}

/* body {
  font-family: "Aleo", serif;
  font-family: "Cormorant", serif;
  font-family: "montserrat", sans-serif;
  font-family: "work-sans", sans-serif;
} */

body {
  line-height: 1.7;
  font-weight: 400;
  font-size: 1.8rem;
  font-family: "Work Sans", serif;
  width: 100%;
  height: 100%;
  overflow: auto;
  position: relative;
  letter-spacing: 0.08rem;
}

ul {
  list-style: none;
}

a:link,
a:visited,
a:hover,
a:active {
  color: #fff;
  text-decoration: none;
}

.hidden {
  display: none !important;
}

.temporary-hide {
  display: none;
}

.page--container {
  opacity: 1;
}

.invisible {
  opacity: 0 !important;
}

.ph {
  text-shadow: none;
}

.ph-fill {
  color: #a1000b;
  font-size: 2.4rem;
}

.icon {
  font-size: 2.4rem;
  cursor: pointer;
}

.noscroll {
  overflow: hidden !important;
}

.underline {
  text-decoration: underline;
}

/* SECTION NAV */
.section-nav {
  background-color: #fdf9f5;
  height: 9rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
}

.container-logo:link,
.container-logo:visited,
.container-logo:hover,
.container-logo:active {
  color: #777;
  height: 9rem;
  display: flex;
  align-items: center;
  font-size: larger;
}

.list-nav {
  display: flex;
  justify-content: center;
  gap: 10%;
  font-size: large;
  font-weight: 600;
}

.list-nav .list-item a:link,
.list-nav .list-item a:visited,
.list-nav .list-item a:hover,
.list-nav .list-item a:active {
  color: #a1000b;
  white-space: nowrap;
}

.container-logo .logo-img {
  height: 75%;
}

/* .section--nav {
  background-color: #fdf9f5;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
  align-items: center;
  height: 7rem;
  padding: 0 1.2rem;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
}

.logo--nav-href {
  justify-self: start;
}

.list-container--nav {
  display: flex;
  gap: 4.8rem;
  white-space: nowrap;
  width: min-content !important;
  font-weight: 600;
  list-style: none;
  cursor: pointer;
}

.item--nav a:link,
.item--nav a:visited,
.item--nav a:hover,
.item--nav a:active {
  color: #a1000b;
  text-decoration: none;
  transition: all 0.3s;
}

.item--nav a:hover,
.item--nav a:active {
  font-weight: 800;
} */

/* .library--link {
  display: inline-block;
  
}

.cur--nav {
  font-weight: 800;
}

.logo-container--nav {
  display: flex;
  align-items: center;
}

.logo--img {
  width: 4.8rem;
  height: 4.8rem;
}

.logo--text {
  white-space: nowrap;
  font-size: 2.1rem;
  color: #777;
}

.lang-container--nav {
  justify-self: end;
} */

.menu {
  display: none;
  box-shadow: 0rem 0rem 0.2rem #999;
  background-color: rgba(255, 255, 255, 0.2);
  height: fit-content;
  padding: 0.5rem 0.8rem;
  cursor: pointer;
}

.section--footer {
  background-color: #000;
  color: #fff;
  display: grid;
  grid-template-rows: 1fr min-content;
}

.list-el--footer {
  list-style: none;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: 90%;
  margin: 0 auto;
  justify-items: center;
  height: 6rem;
}

.list-el--footer.read {
  grid-template-columns: 1fr 1fr;
}

.item-el--footer {
  display: flex;
  align-items: center;
  gap: 1.2rem;
  white-space: nowrap;
}

.icon--contact {
  cursor: pointer;
}

.frankel--link-container {
  border-top: 0.01 solid #fafafa;
  padding: 1rem 0;
}

.frankel--link {
  display: flex;
  align-items: end;
  width: fit-content;
  margin: 0 auto;
  color: #c38547 !important;
  font-size: 2.1rem;
  font-family: "Playwrite HU", cursive;
}

.guitar--img {
  height: 8rem;
}

.copyright--footer {
  justify-items: center;
  margin: 0 auto;
  width: 85%;
  white-space: nowrap;
  font-size: 1.5rem;
  align-content: center;
  text-align: center;
  height: 5rem;
}

/* SECTION INTRODUCTION TO LEARN PAGE */
.section--introduction {
  margin: 0 auto;
  text-align: center;
  background-color: rgba(253, 249, 245, 0.75);
  width: 100%;
  display: flex;
  flex-direction: column;

  /* padding */
  padding: 4.8rem 0 4.8rem 0;
  margin-top: 2.4rem;
  gap: 2.1rem;
}

.section--introduction h3 {
  font-size: 2.4rem;
  letter-spacing: 0.1rem;
}

.section--introduction bold {
  color: #a1000b;
  font-style: italic;
}

.section--introduction p {
  width: 50%;
  margin: 0 auto;
}

/* forms */
.section--form {
  position: fixed;
  top: 0%;
  left: 0%;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  animation: fade-in 0.3s forwards;
  -webkit-animation: fade-in 0.3s forwards;
}

.section-form--lang {
  background-color: rgba(0, 0, 0, 0.7);
}

.icon--close-form {
  color: #333;
  position: absolute;
  top: 0.5%;
  right: 0.5%;
  margin: 1.2rem;
}

.form {
  background-color: #fdf9f5;
  box-shadow: 0 0.4rem 0.4rem 0.6rem rgba(0, 0, 0, 0.04);
  width: 80%;
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  transform: scale(0);
  animation: expand 0.3s forwards;
  /* -webkit-animation: expand 0.3s forwards; */

  /* padding */
  padding: 4.8rem 0;
  gap: 3.6rem;
}

.form--lang {
  display: grid;
  grid-template-columns: 1fr 1fr;

  /* padding */
  padding: 3.6rem;
}

.form--lang p:hover {
  text-decoration: underline;
  cursor: pointer;
}

.form div {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 0 auto;

  /* gap */
  gap: 1.2rem;
}

.form--input,
.form--textarea {
  /* size */
  font-size: 1.7rem;
  padding: 1rem;
}

.form--textarea {
  height: 10rem;
}

.submit-btn {
  width: 50%;
  height: 5rem;
  font-size: 1.7rem;
  background-color: #91000a;
  color: #fff;
  cursor: pointer;
  transition: all 0.2s;
  margin: 0 auto;
  border: 0.1rem solid #91000a;
  border-radius: 3.6rem;
  text-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.submit-btn:hover {
  background-color: #aa1a23;
}

/* card buttons, same format */
.card--btn {
  border: 0.1rem solid #000;
  border-radius: 3.6rem;
  transition: all 0.2s;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  height: 4rem;
  margin: 0 auto;
}

.card--btn-side:link,
.card--btn-side:visited {
  color: #000;
  text-decoration: none;
}

.card--btn-side:hover,
.card--btn-side:active {
  background-color: #a1000b;
  color: #fff;
}

.form--quote {
  text-align: center;
  font-style: italic;
  width: 50% !important;
  margin: 0 auto;
}

.collapse {
  animation: collapse 0.3s forwards;
  /* -webkit-animation: collapse 0.3s forwards; */
}

.fade-in {
  animation: fade-in 0.3s forwards;
  -webkit-animation: fade-in 0.3s forwards;
}

.fade-out {
  animation: fade-out 0.3s forwards;
  -webkit-animation: fade-out 0.3s forwards;
}

@keyframes expand {
  0% {
    transform: scale(0.1);
  }
  20% {
    transform: scale(0.2);
  }
  30% {
    transform: scale(0.3);
  }
  40% {
    transform: scale(0.4);
  }
  50% {
    transform: scale(0.5);
  }
  60% {
    transform: scale(0.6);
  }
  70% {
    transform: scale(0.7);
  }
  80% {
    transform: scale(0.8);
  }
  90% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

/* webkit keyframes see more */

@keyframes collapse {
  0% {
    transform: scale(0.9);
  }
  20% {
    transform: scale(0.8);
  }
  30% {
    transform: scale(0.7);
  }
  40% {
    transform: scale(0.6);
  }
  50% {
    transform: scale(0.5);
  }
  60% {
    transform: scale(0.4);
  }
  70% {
    transform: scale(0.3);
  }
  80% {
    transform: scale(0.2);
  }
  90% {
    transform: scale(0.1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
